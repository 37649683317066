import React, { useState, useEffect } from "react";
import Reveal from "react-reveal";
import Layout from "../components/layouts";
import mtbP1 from "../images/mtb-p-1.png";
import mtbP2 from "../images/mtb-p-2.png";
import mtbP3 from "../images/mtb-p-3.png";
import mtbP4 from "../images/mtb-p-4.png";
import mtb1 from "../images/mtb-1.jpg";
import mtb2 from "../images/mtb-2.jpg";
import mtb3 from "../images/mtb-3.jpg";
import mtb4 from "../images/mtb-4.jpg";
import mtb5 from "../images/mtb-5.jpg";
import mtb6 from "../images/mtb-6.jpg";
import mtb7 from "../images/mtb-7.jpg";
import mtb8 from "../images/mtb-8.jpg";
import mtb9 from "../images/mtb-9.jpg";
import mtb10 from "../images/mtb-10.jpg";
import mtb11 from "../images/mtb-11.jpg";
import mtb12 from "../images/mtb-12.jpg";
import mtb13 from "../images/mtb-13.jpg";
import mtb14 from "../images/mtb-14.jpg";
import mtb15 from "../images/mtb-15.jpg";
import mtbLogo from "../images/mtb-logo-dd.png";


const MeatTheBeetles = () => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState({
    message: "",
    type: null,
  }); 
  const messageClasses = `contact-message ${feedback.type}`;
  useEffect(() => {
    document.documentElement.classList.add("static-nav");
    document.documentElement.classList.add("nav-background");
  });

  const handleSubmit = ((e) => {
    e.preventDefault();
    const formData  = new FormData();

    for(const name in e) {
      formData.append(name, e[name]);
    }

    if (fName !== "" && lName !== "" && email !== "") {
        let regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        if (!regex.test(email)) {
          setFeedback({
            message: 'Please enter a valid E-mail',
            type: 'error'
          });
          return;
        } else {
          fetch("https://globalinheritance.us2.list-manage.com/subscribe/post-json?&c=?", {
            method: "POST",
            body: JSON.stringify(formData),
            mode: 'no-cors',
          }).then(async (response) => {
            setFeedback({
              message: 'Thank you! Your information has been sent',
              type: 'success'
            });
          }).catch((error) => {
            setFeedback({
              message: error.message,
              type: "error",
            });
          });
        }
    } else {
      setFeedback({
        message: 'Please fill out all fields',
        type: 'error'
      });
    }
  });

    return (
      <Layout navBg>
      <div className="mtb-page">
          <div className="mtb-header">
              <div className="mtb-header-logo" />
          </div>
          <div className="mtb-inner">
            <div className="mtb-description">
                <h1>The Edible Insect Revolution Is Here</h1>
                <p>Global Inheritance presents Meat the Beetles, a throw-back cultural experience designed to transport people back to the 60’s, <strong>when the modern environmental movement was just beginning</strong> and popular music encouraged people to open their minds. </p>
                <p>The recent Beatles documentary, Get Back, has piqued curiosity in their music and the overall cultural scene from their era. <strong>Meat the Beetles creates an indelible memory through new behaviors which contribute to shaping our shared future. Insects are an incredibly efficient source of protein – offering nearly 3 times as much compared to traditional livestock.</strong> Insects also require far less land to manage, less feed to grow and harvest, and 90% less water to cultivate – all while emitting little to no greenhouse gases!</p>
            </div>
            <div className="mtb-body">
              <img src={mtb1} />
          </div>
        </div>

        <Reveal effect="animated fadeIn">

          <div className="mtb-inner">
          <div className="mtb-body reverse">
              <img className="mtb-dd-logo" src={mtbLogo} />
              </div>
            <div className="mtb-description reverse">
              <h1>Meat Up With the Beetles This September at Desert Daze Music Festival in Southern California</h1>
              <p>Are your tastebuds ready to Meat the Beetles and stage dive into the world of edible insects?</p>
              <p>
              You’ll be greeted by a visual explosion of lights, sounds, and art connected to the world of entomophagy and psychedelic rock. The space is centered around a <strong>Meat the Beetles-themed food truck and Record Store</strong> - with information on insect species, their culinary uses, and benefits covering the walls as posters and record sleeves.
              </p>
              <p>
              <strong>Renowned insect chef and Brooklyn Bugs Founder</strong> <a href="https://www.theguardian.com/environment/2022/sep/09/insects-food-menu?mc_cid=c02b9ec74a&mc_eid=1971b2c031" target="_blank" rel="noreferrer noopener">Joseph Yoon</a> and the Global Inheritance team will prepare hot and cold insect dishes offering festival attendees and performing bands the opportunity to try a range of edible insect dishes every day in the main venue. Experience our tasting menu and join the editable insect revolution! 
              </p>
            </div>
          </div>
          </Reveal>
          
        <Reveal effect="animated fadeIn">
          <div className="mtb-inner">
            <div className="mtb-description">
              <h1>Meat the Beetles Desert Daze Menu</h1>
                <ul>
                  <li>Avocado, Beets, Grasshoppers, Black Ants, Mustard Greens</li>
                  <li>Grasshopper, Black Ant Guacamole, Tortilla Chip</li>
                  <li>Cricket Gougeres, Pecorino Romano</li>
                  <li>Grasshopper Spanish Tortilla, Potatoes, Spinach, Cricket Aioli</li>
                  <li>Cricket, Eggplant, Crimini, Tomatoes, Cricket Ricotta, Crostini </li>
                  <li>Cricket Eggplant Parmesan Slider</li>
                  <li>Edamame Cricket Hummus - Cricket Flatbread, Grasshoppers, Pickled Carrots and Daikon</li>
                  <li>Mealworm Mushroom Meatball, Mealworm Marinara, Mozzarella, Basil</li>
                  <li>Mealworm Pajeon (Veggie Scallion Pancake), Ponzu Cricket Aioli</li>
                  <li>Grasshopper Disco Tostadas, Cheese Sauce, Cricket Green Goddess, Black Ants</li>
                  <li>Cricket Corn Fritter, Herbs, Cricket Remoulade</li>
                  <li>Mealworm Lasagna, Mealworm Marinara, Spinach, Basil</li>
                  <li>Locust Skewer - BBQ Shrimp </li>
                  <li>Grasshopper Japchae (cellophane noodles), Bell Peppers, Onions, Mushrooms, Greens</li>
                  <li>Mealworm Pajeon (Veggie Scallion Pancake), Ponzu Cricket Aioli</li>
                  <li>Cricket Yu Choi Kimchi, Silken Tofu</li>
                  <li>Gochujang Manchurian Scorpion, Triple Grains, Pickled Chayote and Jalapeños</li>
                  <li>Caramel Popcorn, Grasshoppers, Crickets</li>
                  <li>Chocolate Chip Mealworm and Cricket Cookie Bite</li>
                  <li>Grasshopper, Cricket, and/or Black Ant, Chocolate Apple</li>
                  <li>Cricket Cake, Chocolate Mousse, Palm Weevils</li>
                  <li>Mealworm Chocolate Brownie Bites</li>
                </ul>
                <h6>*Menu Subject to Change Based on Ingredient Availability</h6>
            </div>
          </div>
          </Reveal>

          <Reveal effect="animated fadeIn">
          <div className="mtb-inner">
            <div className="mtb-description">
              <h1 style={{textAlign: "center"}}>Partners</h1>
            </div>
            <div className="mtb-gallery">
              <div className="mtb-p">
                  <a href="https://entomofarms.com/" target="_blank">
                      <img src={mtbP1} />
                  </a>
                  <p><a href="https://www.instagram.com/entomofarms/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></p>
              </div>
              <div className="mtb-p">
                  <a href="https://www.entosense.com/" target="_blank">
                      <img src={mtbP2} />
                  </a>
                  <p><a href="https://www.instagram.com/entosense/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                  <a href="https://twitter.com/entosense" target="_blank"><i className="fa fa-twitter-square" aria-hidden="true"></i></a></p>
              </div>
              <div className="mtb-p">
                  <a href="https://3cricketeers.com/" target="_blank">
                      <img src={mtbP3} />
                  </a>
                  <p><a href="https://www.instagram.com/3cricketeers/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></p>
              </div>
              <div className="mtb-p">
                  <a href="https://www.brooklynbugs.com/" target="_blank">
                      <img src={mtbP4} />
                  </a>
                  <p><a href="https://www.instagram.com/brooklynbugs/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                  <a href="https://twitter.com/Brooklynbugs17" target="_blank"><i className="fa fa-twitter-square" aria-hidden="true"></i></a></p>
                 
              </div>
            </div>
          </div>
          </Reveal>

          <div className="mtb-inner" style={{marginTop: 50}}>
            <Reveal effect="animated fadeIn">
              <div className="mtb-gallery">
              <div className="mtb-ex">
                <img src={mtb1} />
              </div>
              <div className="mtb-ex">
                <img src={mtb2} />
              </div>
              <div className="mtb-ex">
                <img src={mtb3} />
              </div>
              <div className="mtb-ex">
                <img src={mtb4} />
              </div>
              <div className="mtb-ex">
                <img src={mtb5} />
              </div>
              <div className="mtb-ex">
                <img src={mtb6} />
              </div>
              <div className="mtb-ex">
                <img src={mtb7} />
              </div>
              <div className="mtb-ex">
                <img src={mtb8} />
              </div>
              <div className="mtb-ex">
                <img src={mtb9} />
              </div>
              <div className="mtb-ex">
                <img src={mtb10} />
              </div>
              <div className="mtb-ex">
                <img src={mtb11} />
              </div>
              <div className="mtb-ex">
                <img src={mtb12} />
              </div>
              <div className="mtb-ex">
                <img src={mtb13} />
              </div>
              <div className="mtb-ex">
                <img src={mtb14} />
              </div>
              <div className="mtb-ex">
                <img src={mtb15} />
              </div>
              </div>
            </Reveal>
          </div>
          

          <div className="contact" id="contact">
              <div className="form-container">
                  <div className="form-inner application-form-inner">

                      <h1>Contact Meat the Beetles Tour Manager</h1>
                      <form name="applicationForm" className="form application-form app-intern" onSubmit={(e) => handleSubmit(e)}>
                          <input type="hidden" name="u" value="40e700f186279051f3a238f8a"/>
                          <input type="hidden" name="id" value="0704a0a613"/>
                          <input type="hidden" name="TYPE" id="MERGE3" value="INTERN" />
                          <div className="form-field application-field">
                              <input type="text"  placeholder="first name"name="FNAME" id="MERGE1" value={fName} onChange={(e) => setFname(e.currentTarget.value)} />
                              <label>FIRST NAME</label>
                          </div>
                          <div className="form-field application-field">
                              <input type="text"   placeholder="first name"name="LNAME" id="MERGE2" value={lName} onChange={(e) => setLname(e.currentTarget.value)} />
                              <label>LAST NAME</label>
                          </div>
                          <div className="form-field application-field">
                              <input type="email"  placeholder="first name"name="EMAIL" id="MERGE0" value={email}  onChange={(e) => setEmail(e.currentTarget.value)} />
                              <label>EMAIL</label>
                          </div>

                          <div className="submit-container">
                              <button type="submit" className="btn-container">
                                  <div className="btn primary small">
                                  <span>Submit</span>
                                  </div>
                              </button>
                          </div>

                          <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                              <label htmlFor="b_name">Name: </label>
                              <input type="text" name="b_name" tabIndex={-1} value="" placeholder="Freddie" id="b_name"/>

                              <label htmlFor="b_email">Email: </label>
                              <input type="email" name="b_email" tabIndex={-1} value="" placeholder="youremail@gmail.com" id="b_email"/>

                              <label htmlFor="b_comment">Comment: </label>
                              <textarea name="b_comment" tabIndex={-1} placeholder="Please comment" id="b_comment"></textarea>
                          </div>

                          <div className={messageClasses}>{feedback.message}</div>
                      </form>
                      <p>Need more information? Email <a href="mailto:info@globalinheritance.org">info@globalinheritance.org</a></p>
                  </div>
              </div>
                  
              {/* <a href="mailto:pb@globalinheritance.org">Partner</a>
              <a href="mailto:pb@globalinheritance.org">Ask Questions</a> */}

          </div>
      </div>
      </Layout>
  )
}

export default MeatTheBeetles;


